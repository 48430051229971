<template>
  <div id="finished-modal">
    <transition name="fade" appear>
      <div class="modal-overlay"></div>
    </transition>
    <transition name="pop">
      <div class="modal">
        <h1 class="main-heading">Poptávka úspěšně odeslána</h1>
        <img
          src="@/assets/icons/checkmark.png"
          alt="checkmark"
          class="checkmark"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "FinishedModal"
};
</script>

<style scoped>
.checkmark {
  width: 80px;
}

@media screen and (max-width: 900px) {
  .checkmark {
    width: 60px;
  }
}

#finished-modal .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background: rgba(0, 0, 0, 0.3);
}

#finished-modal .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 95;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 80vw;
  min-height: 300px;
  max-height: 500px;
  max-width: 900px;
  background: #fff;
  border-radius: 10px;
  padding: 16px;
}

@media screen and (max-width: 1200px) {
  #finished-modal .modal {
    width: 80vw;
  }
}

@media screen and (max-width: 900px) {
  #finished-modal .modal {
    max-height: 100px;
    height: 100%;
  }
}

#finished-modal .modal .main-heading {
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 2.2rem;
  text-align: center;
  color: #542a01;
  margin-bottom: 20px;
  margin-top: 10px;
}

@media screen and (max-width: 900px) {
  #finished-modal .modal .main-heading {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 600px) {
  #finished-modal .modal .main-heading {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    margin-top: 8px;
  }

  #finished-modal .modal .wrapper {
    overflow-y: scroll;
  }
}

#finished-modal .modal .wrapper .info-text {
  width: 100%;
  color: #5e646a;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
}

#finished-modal .modal .wrapper .info-text p {
  text-align: center;
}

/* Animace */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pop-enter-active,
.pop-leave-active {
  transition: all 0.3s ease;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(1.1);
}
</style>
