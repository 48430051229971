import Vue from "vue";
import Vuex from "vuex";
import router from "../router/router";
import { api } from "./modules/api.module";
import { sudo } from "./modules/sudo.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    currentStep: 1,
    selectedTable: 0,
    selectedCategory: null,
    showModal: false,
    searchedSigns: " ",
    highlightEmptySigns: false,
    nextStepBtn: "VYBRAT TABULKY",
    selectedQuantity: 1,
    currentPosition: 1,
    selectedConfigurations: [],
    configAcceptedModal: false,
    editMode: false,
    pexesoMode: false,
    editedConfiguration: null,
    editedTable: null
  },
  getters: {
    filteredSigns: state => {
      /* TODO: zjednodušit return */
      if (state.selectedCategory) {
        if (state.searchedSigns === "") {
          return state.api.signCategories.filter(
            category => category.id === state.selectedCategory
          )[0].signs;
        } else {
          return state.api.allSigns.filter(items => {
            return items.name
              .toLowerCase()
              .includes(state.searchedSigns.toLowerCase());
          });
        }
      }
    }
  },
  mutations: {
    DISABLE_EDIT_MODE(state) {
      state.editMode = false;
    },
    TOGGLE_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_CART: (state, payload) => {
      state.selectedConfigurations = payload;
    },
    CHANGE_TABLE: (state, payload) => {
      state.selectedTable = payload;
    },
    CHANGE_CATEGORY: (state, payload) => {
      state.selectedCategory = payload;
    },
    CHANGE_CARD: (state, payload) => {
      Vue.set(
        state.api.tables[state.selectedTable].selectedSigns[payload.side][
          payload.cardToChange
        ],
        payload
      );
    },
    SEARCH_SIGNS: (state, payload) => {
      state.searchedSigns = payload;
    },
    GO_NEXT: (state, payload) => {
      if (payload === "/") {
        router.push({
          name: "BoardConfig",
          params: { board_id: state.api.tables[state.selectedTable].id }
        });
      } else if (payload.includes("konfigurace")) {
        let emptyFound = false;
        state.api.tables[state.selectedTable].selectedSigns.forEach(side => {
          side.forEach(sign => {
            if (Object.is(sign.name, "")) {
              emptyFound = true;
            }
          });
        });
        if (emptyFound) {
          state.api.tables[state.selectedTable].highlightEmpty = true;
          state.productConfigFeedback =
            "Před pokračováním musí být vyplněny všechny tabulky";
        } else {
          state.api.tables[state.selectedTable].highlightEmpty = false;
          state.productConfigFeedback = null;
          router.push({ name: "ConfigSummary" });
        }
      } else {
        let copiedData = JSON.parse(
          JSON.stringify(state.api.tables[state.selectedTable])
        );
        copiedData.quantity = state.selectedQuantity;
        copiedData.isToggled = false;
        let cardback = state.api.cardBacks.filter(cb => {
          return cb.id === copiedData.selectedCardback;
        })[0];
        copiedData.cardback_img_name = cardback.cover_img_name;
        copiedData.cardback_name = cardback.name;
        state.selectedQuantity = 1;
        state.selectedConfigurations.push(copiedData);
        state.configAcceptedModal = true;
        state.pexesoMode = false;
        const parsed = JSON.stringify(state.selectedConfigurations);
        localStorage.setItem("cart", parsed);
      }
    },
    UPDATE_NAV_BTN: (state, payload) => {
      state.nextStepBtn = payload[0];
      state.currentStep = payload[1];
    },
    CHANGE_QUANTITY: (state, payload) => {
      state.selectedQuantity = state.selectedQuantity + payload;
    },
    CHANGE_QUANTITY_OF_SELECTED: (state, payload) => {
      if (
        state.selectedConfigurations[payload.index].quantity + payload.value !==
        0
      ) {
        state.selectedConfigurations[payload.index].quantity =
          state.selectedConfigurations[payload.index].quantity + payload.value;
      }
      const parsed = JSON.stringify(state.selectedConfigurations);
      localStorage.setItem("cart", parsed);
    },
    SET_QUANTITY: (state, payload) => {
      state.selectedQuantity = payload;
    },
    SET_CART_QUANTITY(state, payload) {
      state.selectedConfigurations[payload.index].quantity = payload.value;
      const parsed = JSON.stringify(state.selectedConfigurations);
      localStorage.setItem("cart", parsed);
    },
    SET_QUANTITY_OF_SELECTED: (state, payload) => {
      state.selectedConfigurations[payload.index].quantity = payload;
    },
    CHANGE_CURRENT_POS: (state, payload) => {
      state.currentPosition = state.currentPosition + payload;
    },
    SET_CURRENT_POS: (state, payload) => {
      state.currentPosition = payload;
    },
    PURGE_CONFIGS(state) {
      state.api.tables.forEach(table => {
        table.selectedSigns.forEach(side => {
          side.forEach((sign, index) => {
            let emptySign = {
              item_id: "empty" + index,
              name: "",
              front_image: "/images/emptys.png",
              back_image: "/images/emptys.png"
            };
            Object.assign(sign, emptySign);
          });
        });
      });
    },
    TOGGLE_ADDED_MODAL(state, payload) {
      state.api.tables.forEach(table => {
        table.selectedSigns.forEach(side => {
          side.forEach((sign, index) => {
            let emptySign = {
              item_id: "empty" + index,
              name: "",
              front_image: "/images/emptys.png",
              back_image: "/images/emptys.png"
            };
            Object.assign(sign, emptySign);
          });
        });
      });
      state.configAcceptedModal = !state.configAcceptedModal;
      router.push({ name: payload });
    },
    TOGGLE_EDIT_MODE(state, payload) {
      state.editMode = !state.editMode;
      state.editedConfiguration = payload.index;
      state.editedTable = state.api.tables.findIndex(
        i => i.id === payload.item.id
      );
      state.selectedTable = state.editedTable;
      state.api.tables[
        state.api.tables.map(table => table.id).indexOf(payload.item.id)
      ].quantity = payload.quantity;
      state.api.tables[state.selectedTable].selectedSigns.forEach(
        (i, index) => {
          Object.assign(i, payload.item.selectedSigns[index]);
        }
      );
    },
    TOGGLE_PEXESO_MODE(state, payload) {
      state.pexesoMode = payload.value;

      if (payload) {
        state.api.tables[payload.index]["selectedCardback"] =
          state.api.cardBacks[0].id;
      }
    },
    UPDATE_TABLE(state) {
      /**/
      let emptyFound = false;
      state.api.tables[state.selectedTable].selectedSigns.forEach(side => {
        side.forEach(sign => {
          if (Object.is(sign.name, "")) {
            emptyFound = true;
          }
        });
      });
      /**/
      if (emptyFound) {
        state.api.tables[state.selectedTable].highlightEmpty = true;
        state.productConfigFeedback =
          "Před pokračováním musí být vyplněny všechny tabulky";
      } else {
        let copiedData = JSON.parse(
          JSON.stringify(state.api.tables[state.selectedTable])
        );
        state.selectedConfigurations[
          state.editedConfiguration
        ].selectedSigns.forEach((i, index) => {
          Object.assign(i, copiedData.selectedSigns[index]);
        });
        state.editMode = false;
        router.push({ name: "Cart" });
      }
    },
    REMOVE_CONFIGURATION(state, payload) {
      state.selectedConfigurations.splice(payload, 1);
      /* aktualizace localstorage */
      const parsed = JSON.stringify(state.selectedConfigurations);
      localStorage.setItem("cart", parsed);
    }
  },
  actions: {
    disableEditMode({ commit }) {
      commit("DISABLE_EDIT_MODE");
    },
    toggleLoading({ commit }, payload) {
      commit("TOGGLE_LOADING", payload);
    },
    setCart: ({ commit }, payload) => {
      commit("SET_CART", payload);
    },
    changeTable: ({ commit }, payload) => {
      commit("CHANGE_TABLE", payload);
    },
    changeCategory: ({ commit }, payload) => {
      commit("CHANGE_CATEGORY", payload);
    },
    changeCard: ({ commit }, payload) => {
      commit("CHANGE_CARD", payload);
    },
    searchSigns: ({ commit }, payload) => {
      commit("SEARCH_SIGNS", payload);
    },
    goNext: ({ commit }, payload) => {
      commit("GO_NEXT", payload);
    },
    updateNavBtn: ({ commit }, payload) => {
      commit("UPDATE_NAV_BTN", payload);
    },
    changeQuantity: ({ commit }, payload) => {
      commit("CHANGE_QUANTITY", payload);
    },
    changeQuantityOfSelected: ({ commit }, payload) => {
      commit("CHANGE_QUANTITY_OF_SELECTED", payload);
    },
    setQuantity: ({ commit }, payload) => {
      commit("SET_QUANTITY", payload);
    },
    setCartQuantity({ commit }, payload) {
      commit("SET_CART_QUANTITY", payload);
    },
    setQuantityOfSelected: ({ commit }, payload) => {
      commit("SET_QUANTITY_OF_SELECTED", payload);
    },
    changeCurrentPos: ({ commit }, payload) => {
      commit("CHANGE_CURRENT_POS", payload);
    },
    setCurrentPos: ({ commit }, payload) => {
      commit("SET_CURRENT_POS", payload);
    },
    purgeConfigs({ commit }) {
      commit("PURGE_CONFIGS");
    },
    toggleAddedModal({ commit }, payload) {
      commit("TOGGLE_ADDED_MODAL", payload);
    },
    toggleEditMode({ commit }, payload) {
      commit("TOGGLE_EDIT_MODE", payload);
    },
    togglePexesoMode({ commit }, payload) {
      commit("TOGGLE_PEXESO_MODE", payload);
    },
    updateTable({ commit }) {
      commit("UPDATE_TABLE");
    },
    removeConfiguration({ commit }, payload) {
      commit("REMOVE_CONFIGURATION", payload);
    }
  },
  modules: {
    api,
    sudo
  }
});
