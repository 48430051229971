<template>
  <div id="config-summary">
    <Navigator></Navigator>
    <div class="container">
      <h1 class="main-heading">SHRNUTÍ KONFIGURACE</h1>
      <div class="summary-card">
        <div class="general-info">
          <div class="item-thumbnail">
            <img :src="tables[selectedTable].title_image" alt="table-img" />
          </div>
          <div class="item-name">
            <span>{{ tables[selectedTable].name }}</span>
          </div>
          <div class="quantity item item-quantity">
            <div class="minus" @click="changeQuantity(-1)">
              <img
                class="symbol-img"
                src="@/assets/icons/minus.png"
                alt="minus-btn"
              />
            </div>
            <input
              class="quantity-input"
              min="0"
              type="number"
              :value="[[selectedQuantity]]"
              @change="event => setQuantity(event)"
            />
            <div class="plus" @click="changeQuantity(1)">
              <img
                class="symbol-img"
                src="@/assets/icons/plus.png"
                alt="plus-btn"
              />
            </div>
          </div>
        </div>
        <div class="sub-header">
          <p class="subheading">Vybrané Tabulky</p>
        </div>
        <div class="details">
          <div
            class="side-detail"
            v-for="(signSet, index) in tables[selectedTable].selectedSigns"
            :key="index"
          >
            <span
              class="side-heading"
              v-if="tables[selectedTable].selectedSigns.length > 1"
              >Tabule {{ index + 1 }}</span
            >
            <div class="items-wrapper"></div>
            <span v-for="(sign, index2) in signSet" :key="index2">{{
              sign.name
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <NavigatorBtn class="navigator-btn"></NavigatorBtn>
  </div>
</template>

<script>
import Navigator from "@/components/Navigator";
import NavigatorBtn from "@/components/NavigatorBtn";
import { mapState } from "vuex";
import NProgress from "nprogress";
import { api } from "../store/modules/api.module";

export default {
  name: "ProductSummary",
  components: {
    Navigator: Navigator,
    NavigatorBtn: NavigatorBtn
  },
  computed: {
    ...mapState(["selectedTable", "selectedQuantity"]),
    ...mapState("api", ["tables"])
  },
  methods: {
    changeQuantity(payload) {
      this.$store.dispatch("changeQuantity", payload);
    },
    setQuantity(event) {
      this.$store.dispatch("setQuantity", parseInt(event.target.value));
    }
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    NProgress.start();
    if (api.state.tables.length && api.state.allSigns.length) {
      NProgress.done();
      next();
    } else {
      NProgress.done();
      next({ name: "BoardSelect" });
    }
  },
  mounted() {
    this.$store.dispatch("updateNavBtn", ["PŘIDAT DO POPTÁVKY", 3]);
  }
};
</script>

<style scoped>
#config-summary .container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
}

#config-summary .container .main-heading {
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 2.1rem;
  text-align: center;
  color: #542a01;
  margin-bottom: 20px;
  margin-top: 15px;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  #config-summary .container .main-heading {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 900px) {
  #config-summary .container .main-heading {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 900px) {
  #config-summary .container .main-heading {
    font-size: 24px;
  }
}

#config-summary .container .summary-card {
  margin-bottom: 100px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 1200px;
  background-color: #c8e6c9;
  padding: 30px;
}

@media screen and (max-width: 1200px) {
  #config-summary .container .summary-card {
    width: 1000px;
  }
}

@media screen and (max-width: 900px) {
  #config-summary .container .summary-card {
    width: 100%;
    padding: 30px 10px;
  }
}

#config-summary .container .summary-card .general-info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  #config-summary .container .summary-card .general-info {
    justify-content: space-around;
  }
}

#config-summary .container .summary-card .general-info .item-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

@media screen and (max-width: 600px) {
  #config-summary .container .summary-card .general-info .item-thumbnail {
    display: none;
  }
}

#config-summary .container .summary-card .general-info .item-thumbnail img {
  max-width: 90%;
}

@media screen and (max-width: 900px) {
  #config-summary .container .summary-card .general-info .item-thumbnail img {
    min-width: 70%;
  }
}

#config-summary .container .summary-card .general-info .item-name {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}

#config-summary .container .summary-card .general-info .item-name span {
  text-transform: uppercase;
  font-family: "PT Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  color: #542a01;
}

@media screen and (max-width: 900px) {
  #config-summary .container .summary-card .general-info .item-name span {
    font-size: 1.2rem;
    white-space: nowrap;
    margin-left: 5px;
    margin-right: 15px;
  }
}

#config-summary .container .summary-card .general-info .item-quantity {
  width: 30%;
  height: 100%;
  font-family: "PT Sans", sans-serif;
  font-size: 1rem;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  #config-summary .container .summary-card .general-info .item-quantity {
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  #config-summary .container .summary-card .general-info .item-quantity {
    width: auto;
  }
}

#config-summary
  .container
  .summary-card
  .general-info
  .item-quantity
  .symbol-img {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 900px) {
  #config-summary
    .container
    .summary-card
    .general-info
    .item-quantity
    .symbol-img {
    width: 20px;
    height: 20px;
  }
}

#config-summary .container .summary-card .general-info .item-quantity .minus,
#config-summary .container .summary-card .general-info .item-quantity .plus {
  background-color: #ffc400;
  border-radius: 50%;
  padding: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
}

@media screen and (max-width: 900px) {
  #config-summary .container .summary-card .general-info .item-quantity .minus,
  #config-summary .container .summary-card .general-info .item-quantity .plus {
    width: 45px;
    height: 45px;
    margin: 10px 20px;
  }
}

@media screen and (max-width: 600px) {
  #config-summary .container .summary-card .general-info .item-quantity .minus,
  #config-summary .container .summary-card .general-info .item-quantity .plus {
    margin: 10px 8px;
    width: 35px;
    height: 35px;
  }
}

#config-summary
  .container
  .summary-card
  .general-info
  .item-quantity
  .quantity-input {
  font-family: "PT Sans", sans-serif;
  font-size: 0.9rem;
  text-align: center;
  border: 1px solid #777;
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 900px) {
  #config-summary
    .container
    .summary-card
    .general-info
    .item-quantity
    .quantity-input {
    width: 50px;
    height: 50px;
    font-size: 1.1rem;
  }
}

#config-summary .container .summary-card .sub-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}

#config-summary .container .summary-card .sub-header p {
  font-family: "kg_life_is_messy", sans-serif;
  color: black;
  text-transform: uppercase;
  margin-left: 20%;
}

@media screen and (max-width: 900px) {
  #config-summary .container .summary-card .sub-header p {
    text-align: center;
    margin-top: 15px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 600px) {
  #config-summary .container .summary-card .sub-header p {
    font-size: 1rem;
    margin-left: 10px;
  }
}

#config-summary .container .summary-card .details {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-left: 20%;
}

@media screen and (max-width: 900px) {
  #config-summary .container .summary-card .details {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  #config-summary .container .summary-card .details {
    grid-template-columns: repeat(1, 1fr);
  }
}

#config-summary .container .summary-card .details .side-detail {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 200px;
  margin-bottom: 30px;
}

@media screen and (max-width: 600px) {
  #config-summary .container .summary-card .details .side-detail {
    padding: 10px;
    max-width: 100%;
    width: 100%;
  }
}

#config-summary .container .summary-card .details .side-detail .side-heading {
  font-weight: bold;
  margin-bottom: 3px;
}

#config-summary .container .summary-card .details .side-detail span {
  font-family: "PT Sans", sans-serif;
  font-size: 1.1rem;
  line-height: 1.3rem;
}

@media screen and (min-width: 1200px) {
  .navigator-btn {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .navigator-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 49;
  }
}
</style>
