import { render, staticRenderFns } from "./ConfigSummary.vue?vue&type=template&id=3201c589&scoped=true&"
import script from "./ConfigSummary.vue?vue&type=script&lang=js&"
export * from "./ConfigSummary.vue?vue&type=script&lang=js&"
import style0 from "./ConfigSummary.vue?vue&type=style&index=0&id=3201c589&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3201c589",
  null
  
)

export default component.exports