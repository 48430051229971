<template>
  <div id="app">
    <Navbar></Navbar>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <ConfigAcceptedModal v-if="configAcceptedModal"></ConfigAcceptedModal>
  </div>
</template>

<style lang="css">
@font-face {
  font-family: "kg_life_is_messy";
  src: local("kg_life_is_messy"),
    url("./assets/fonts/KGLifeisMessy.ttf") format("truetype");
}

@font-face {
  font-family: "PT Sans";
  src: local("PT Sans"),
    url("./assets/fonts/PTSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PT Sans";
  src: local("PT Sans"),
    url("./assets/fonts/PTSans-Bold.ttf") format("truetype");
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* ANIMATIONS*/
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.slide-up-enter {
  opacity: 0;
  transform: translateY(300px);
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.4s ease-out;
}

.slide-up-leave-to {
  opacity: 0;
}

.pop-enter-active,
.pop-leave-active {
  transition: all 1s ease;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(1.1);
}
</style>

<script>
import ConfigAcceptedModal from "@/components/ConfigAcceptedModal";
import Navbar from "@/components/Navbar";
import { mapState } from "vuex";

export default {
  components: {
    ConfigAcceptedModal,
    Navbar
  },
  computed: {
    ...mapState(["configAcceptedModal"])
  },
  watch: {
    '$route':{
      handler: (to) => {
        document.title = to.meta || 'Konfigurátor'
      },
      immediate: true
    }
  },
  mounted() {
    if (localStorage.getItem("cart")) {
      try {
        this.$store.dispatch(
          "setCart",
          JSON.parse(localStorage.getItem("cart"))
        );
      } catch (e) {
        localStorage.removeItem("cart");
      }
    }
  },
  created() {
    /* Nastavení informace o ukázání informačního modalu do sessionStorage */
    sessionStorage.setItem("instructionModalShown", "false");
  }
};
</script>
