<template>
  <div class="aselect" :data-value="value" :data-list="signCategories">
    <div class="selector" @click="visible = !visible">
      <div class="label">
        <span>{{ value }}</span>
      </div>
      <div class="arrow" :class="{ expanded: visible }"></div>
      <div :class="{ hidden: !visible, visible }">
        <ul>
          <li
            :class="{ current: category === value }"
            v-for="(category, index) in signCategories"
            :key="index"
            @click="changeCategory(category)"
          >
            {{ category.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Dropdown",
  data() {
    return {
      value: "Vyberte kategorii",
      visible: false
    };
  },
  computed: {
    ...mapState("api", ["signCategories"])
  },
  created() {
    this.value = this.signCategories[0].name;
  },
  methods: {
    changeCategory(category) {
      this.value = category.name;
      this.$store.dispatch("changeCategory", category.id);
    }
  }
};
</script>

<style scoped lang="css">
.aselect {
  width: 39%;
  font-family: "PT Sans", sans-serif;
  height: 100%;
}
@media screen and (max-width: 600px) {
  .aselect {
    width: 100%;
    margin-bottom: 10px;
  }
}
.aselect .selector {
  border: 1px solid gainsboro;
  border-radius: 13px;
  background: #f8f8f8;
  position: relative;
  z-index: 1;
  height: 100%;
}
.aselect .selector .arrow {
  position: absolute;
  right: 10px;
  top: 40%;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #888;
  transform: rotateZ(0deg) translateY(0px);
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
}
.aselect .selector .expanded {
  transform: rotateZ(180deg) translateY(2px);
}
.aselect .selector .label {
  display: block;
  padding: 12px;
  font-size: 16px;
  color: #888;
  height: 100%;
}
.aselect ul {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  border: 1px solid gainsboro;
  position: absolute;
  z-index: 100;
  background: #fff;
}
.aselect li {
  padding: 12px;
  color: #666;
  z-index: 100;
}
.aselect li:hover {
  color: white;
  background: #ffc400;
}
.aselect .current {
  background: #eaeaea;
}
.aselect .hidden {
  visibility: hidden;
}
.aselect .visible {
  visibility: visible;
}
</style>
