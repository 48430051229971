import Vue from "vue";
import VueRouter from "vue-router";
import BoardSelect from "@/views/BoardSelect";
import BoardConfig from "@/views/BoardConfig";
import ConfigSummary from "@/views/ConfigSummary";
import Cart from "@/views/Cart";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "BoardSelect",
    component: BoardSelect,
    meta: 'Výběr tabule'
  },
  {
    path: "/konfigurace/:board_id",
    name: "BoardConfig",
    component: BoardConfig,
    meta: 'Konfigurace tabule'
  },
  {
    path: "/shrnuti",
    name: "ConfigSummary",
    component: ConfigSummary,
    meta: 'Souhrn konfigurace'
  },
  {
    path: "/kosik",
    name: "Cart",
    component: Cart,
    meta: 'Košík'
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
