import TableServices from "@/services/TableServices";
import store from "../store";

export const api = {
  namespaced: true,
  state: {
    tables: [],
    signCategories: [],
    allSigns: [],
    cardBacks: []
  },
  mutations: {
    PURGE_CONFIGURATION(state) {
      state.tables.forEach(table => {
        table.highlightEmpty = false;
        let signLayout = [];
        for (let i = 0; i < table.tables; i++) {
          signLayout.push([]);
          for (let n = 0; n < table.rows * table.columns; n++) {
            let emptySign = {
              item_id: "empty" + n,
              name: "",
              front_image: "/conf/images/empty.png",
              back_image: "/conf/images/empty.png"
            };
            signLayout[i].push(emptySign);
          }
        }
        table.selectedSigns = signLayout;
      });
    },
    SET_CARDBACKS(state, cardbacks) {
      state.cardBacks = cardbacks;
    },
    SET_BOARDS: (state, boards) => {
      state.tables = boards;
    },
    SET_CATEGORIES: (state, data) => {
      state.signCategories[data.index] = data.data;
    },
    SET_SIGNS: (state, data) => {
      state.signCategories[data.index].signs = data.data;
      state.allSigns.push(...data.data);
    },
    CHANGE_CARD(state, data) {
      Object.assign(
        state.tables[data.selectedTable].selectedSigns[data.side][
          data.cardToChange
        ],
        data.sign
      );
    },
    CHANGE_CARD_BACK(state, data) {
      state.tables[data.board_index].selectedCardback = data.cardback_id;
    }
  },
  actions: {
    fetchBoards({ commit }) {
      return TableServices.getBoards()
        .then(boards => {
          //  tvorba uloziste pro zvolene tabulky
          boards.data.forEach(table => {
            table.highlightEmpty = false;
            let signLayout = [];
            for (let i = 0; i < table.tables; i++) {
              signLayout.push([]);
              for (let n = 0; n < table.rows * table.columns; n++) {
                let emptySign = {
                  item_id: "empty" + n,
                  name: "",
                  front_image: "/conf/images/empty.png",
                  back_image: "/conf/images/empty.png"
                };
                signLayout[i].push(emptySign);
              }
            }
            table.selectedSigns = signLayout;
          });
          commit("SET_BOARDS", boards.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    fetchCategories({ commit, dispatch }) {
      return TableServices.getCategories()
        .then(categories => {
          store.dispatch("changeCategory", categories.data[0].id);
          Object.values(categories.data).forEach((category, index) => {
            commit("SET_CATEGORIES", { index: index, data: category });
            dispatch("fetchSigns", { id: category.id, index: index });
          });
          dispatch("fetchCardBacks");
        })
        .catch(err => {
          console.log(err);
        });
    },
    fetchSigns(context, data) {
      return TableServices.getSigns(data.id)
        .then(json => {
          context.commit("SET_SIGNS", {
            data: json.data,
            index: data.index
          });
          sessionStorage.setItem("confId", json);
        })
        .catch(err => {
          console.log(err);
        });
    },
    fetchCardBacks({ commit }) {
      TableServices.getCardBacks()
        .then(res => {
          commit("SET_CARDBACKS", res.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeCard({ commit }, data) {
      commit("CHANGE_CARD", data);
    },
    changeCardBack({ commit }, data) {
      commit("CHANGE_CARD_BACK", data);
    },
    purgeConfiguration({ commit }) {
      commit("PURGE_CONFIGURATION");
    }
  }
};
