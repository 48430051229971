<template>
  <div id="navigator">
    <div class="left"></div>
    <div class="progress" v-show="!editMode">
      <div class="step">
        <router-link :to="{ name: 'BoardSelect' }">
          <div class="num" :class="{ active: currentStep > 0 }">1</div>
        </router-link>
        <div class="text">VOLBA TABULE</div>
      </div>
      <div class="step">
        <router-link
          :to="{ name: 'BoardConfig', params: { board_id: tables[selectedTable].id } }"
        >
          <div class="num" :class="{ active: currentStep > 1 }">2</div>
        </router-link>
        <div class="text">VÝBĚR TABULEK</div>
      </div>
      <div class="step">
        <div class="num" :class="{ active: currentStep > 2 }">3</div>
        <div class="text">SOUHRN</div>
      </div>
    </div>
    <div class="right">
      <button class="next-step" @click="goNext(currentRoute)" v-if="!editMode">
        {{ nextStepBtn }}
      </button>
      <button
        class="next-step"
        @click="
          $store.dispatch('updateTable');
        "
        v-else
      >
        ULOŽIT ZMĚNY
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["selectedTable", "currentStep", "nextStepBtn", "editMode"]),
    ...mapState("api", ["tables"]),
    currentRoute() {
      return this.$route.path;
    }
  },
  methods: {
    goNext(currentRoute) {
      this.$store.dispatch("goNext", currentRoute);
    }
  }
};
</script>

<style scoped>
#navigator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  width: 100%;
  height: 80px;
  margin-top: 10px;
}
@media screen and (max-width: 600px) {
  #navigator {
    height: 68px;
  }
}
#navigator .right {
  width: 25%;
}
@media screen and (max-width: 1200px) {
  #navigator .right {
    display: none;
  }

  .anchor {
    display: block;
  }
}
#navigator .right .next-step {
  background-color: #ffc400;
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 1.3rem;
  padding: 10px 10px;
  color: #542a01;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 13px;
  width: 300px;
}
#navigator .left {
  width: 25%;
}
@media screen and (max-width: 1200px) {
  #navigator .left {
    display: none;
  }
}
#navigator .progress {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  width: 50%;
}
@media screen and (max-width: 1200px) {
  #navigator .progress {
    justify-content: space-between;
    width: 100%;
    padding: 0 90px;
  }
}
@media screen and (max-width: 600px) {
  #navigator .progress {
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }
}
#navigator .progress .step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  margin: 0 30px;
}
@media screen and (max-width: 900px) {
  #navigator .progress .step {
    margin: 0;
    width: 33%;
  }
}
#navigator .progress .step a {
  text-decoration: none;
}
#navigator .progress .step .num {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #542a01;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
  cursor: pointer;
  color: #542a01;
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  #navigator .progress .step .num {
    width: 1.8rem;
    height: 1.8rem;
    font-size: 1.1rem;
  }
}
#navigator .progress .step .text {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 1rem;
}
@media screen and (max-width: 600px) {
  #navigator .progress .step .text {
    font-size: 0.85rem;
  }
}
.num.active {
  color: #fff !important;
  background-color: #542a01;
}
</style>
