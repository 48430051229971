import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import "./assets/tailwind.css";

/* NProgress CSS */
import "nprogress/nprogress.css";

Vue.config.productionTip = false;
Vue.use(require("vue-prevent-parent-scroll"));

// store.dispatch("api/fetchBoards").then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
// });
