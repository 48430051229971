<template>
  <div id="product-choice">
    <Navigator></Navigator>
    <div class="wrapper">
      <div class="left-column">
        <div class="preview">
          <transition name="fade" mode="out-in" v-if="tables[selectedTable]">
            <img
              class="main-image"
              :src="tables[selectedTable].title_image"
              :key="tables[selectedTable].id"
              alt="table-img"
            />
          </transition>
        </div>
        <div class="details" v-if="tables[selectedTable]">
          <p>{{ tables[selectedTable].dimension }}</p>
          <p>{{ tables[selectedTable].description }}</p>
        </div>
      </div>
      <div class="right-column">
        <div class="products">
          <h1>VYBERTE TABULI</h1>
          <transition-group
            class="product-container"
            tag="div"
            name="slide-up"
            appear
          >
            <div
              class="product-card"
              :class="{ highlight: table.id === chosenTable }"
              @click="
                changeTable(index);
                chosenTable = table.id;
              "
              v-for="(table, index) in tables"
              :key="index"
            >
              <div class="product-image">
                <img class="card-image" :src="table.title_image" alt="" />
              </div>
              <div class="product-name">
                <p class="card-title">{{ table.name }}</p>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
    <NavigatorBtn class="navigator-btn"></NavigatorBtn>
  </div>
</template>

<script>
import Navigator from "@/components/Navigator";
import NavigatorBtn from "@/components/NavigatorBtn";
import { mapState } from "vuex";
import NProgress from "nprogress";
import store from "../store/store";

export default {
  name: "BoardSelect",
  components: {
    Navigator: Navigator,
    NavigatorBtn: NavigatorBtn
  },
  data() {
    return {
      chosenTable: null,
      configModal: false
    };
  },
  computed: {
    ...mapState(["selectedTable"]),
    ...mapState("api", ["tables"])
  },
  methods: {
    changeTable(index) {
      this.$store.dispatch("changeTable", index);
      if (this.tables[index].is_pexeso) {
        this.$store.dispatch("togglePexesoMode", { value: true, index: index });
      } else {
        this.$store.dispatch("togglePexesoMode", {
          value: false,
          index: index
        });
      }
    }
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    NProgress.start();
    store.dispatch("api/fetchCategories");
    store.dispatch("api/fetchBoards").then(() => {
      NProgress.done();
      next();
    });
  },
  mounted() {
    this.$store.dispatch("updateNavBtn", ["VYBRAT TABULKY", 1]);
  }
};
</script>

<style scoped lang="css">
.product-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: -10px;
  width: 500px;
}

@media screen and (max-width: 600px) {
  .product-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin: -10px;
    width: 100vw;
  }
}

@media screen and (max-width: 900px) {
  .product-container {
    max-width: 700px;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    max-width: 900px;
  }

  .product-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    margin: -10px;
    width: 100vw;
    max-width: 850px;
    padding: 20px 50px;
  }

  .product-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
  }
}

.product-card {
  flex: 1 1 33%;
  margin: 10px;
}

.product-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.card-image {
  max-width: 200px;
}

.product-name {
  height: 20%;
}

#product-choice .wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  max-height: 800px;
}

#product-choice .wrapper .left-column {
  width: 50%;
}

#product-choice .wrapper .left-column .preview {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}
#product-choice .wrapper .left-column .preview .main-image {
  max-width: 100%;
  max-height: 500px;
}
#product-choice .wrapper .left-column .details {
  font-family: "PT Sans", sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
  color: #542a01;
}
#product-choice .wrapper .right-column {
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

#product-choice .wrapper .right-column .products h1 {
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 2.1rem;
  text-align: center;
  color: #542a01;
  margin-bottom: 20px;
  margin-top: 15px;
}

@media screen and (max-width: 600px) {
  #product-choice .wrapper {
    max-width: 100%;
    justify-content: center;
    max-height: none;
  }

  #product-choice .wrapper .left-column {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  #product-choice .wrapper {
    max-width: 100%;
    justify-content: center;
    max-height: none;
  }

  #product-choice .wrapper .left-column {
    display: none;
  }

  #product-choice .wrapper .right-column {
    width: 100%;
  }

  #product-choice .wrapper .right-column .products h1 {
    margin-bottom: 10px;
    margin-top: 8px;
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 1200px) {
  #product-choice .wrapper .right-column .products h1 {
    font-size: 1.8rem;
  }

  #product-choice .wrapper .right-column .products {
    min-height: 350px;
  }
}
@media screen and (max-width: 600px) {
  #product-choice .wrapper .right-column .products h1 {
    font-size: 1.6rem;
  }
}
#product-choice .wrapper .right-column .products {
  padding: 10px 15px;
  min-height: 470px;
  max-height: 85vh;
  overflow-y: scroll;
  scroll-padding-bottom: 120px;
}
@media screen and (max-width: 1200px) {
  #product-choice .wrapper .right-column .products {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 70px;
  }

  #product-choice
    .wrapper
    .right-column
    .products
    .product-container
    .product-card {
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  #product-choice .wrapper .right-column .products {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 70px;
  }

  #product-choice
    .wrapper
    .right-column
    .products
    .product-container
    .product-card {
    max-height: 300px;
    padding: 1rem 0;
  }

  #product-choice
    .wrapper
    .right-column
    .products
    .product-container
    .product-card
    .card-title {
    font-size: 1.6rem;
  }

  #product-choice
    .wrapper
    .right-column
    .products
    .product-container
    .product-card
    .card-image {
  }
}

@media screen and (max-width: 450px) {
  #product-choice
    .wrapper
    .right-column
    .products
    .product-container
    .product-card {
    padding: 1rem 0;
  }
}
#product-choice
  .wrapper
  .right-column
  .products
  .product-container
  .product-card {
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  cursor: pointer;
}

#product-choice
  .wrapper
  .right-column
  .products
  .product-container
  .product-card
  .card-image {
  height: 100%;
}

#product-choice
  .wrapper
  .right-column
  .products
  .product-container
  .product-card
  .card-title {
  font-family: "PT Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

#product-choice .wrapper .right-column .next-step {
  background-color: #ffc400;
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 1.5rem;
  padding: 13px 40px;
  color: #542a01;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 13px;
  margin: 20px;
}

@media screen and (min-width: 1200px) {
  .navigator-btn {
    display: none !important;
  }
}
@media screen and (max-width: 1200px) {
  .navigator-btn {
    position: absolute;
    bottom: -40px;
  }
}
@media screen and (max-width: 1200px) {
  .navigator-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
  }
}

.product-card.highlight {
  transform: scale(0.96);
  box-shadow: 0 0 4px 3px #542a01 !important;
  transition: all 0.2s ease-out;
}
</style>
