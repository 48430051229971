<template>
  <div id="searchbox">
    <input
      type="text"
      v-model="search"
      @keyup="searchSigns"
      placeholder="Hledat ve všech kategoriích"
    />
    <div class="icon-holder">
      <img src="@/assets/icons/search-50.png" alt="search-icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBox",
  data() {
    return {
      search: ""
    };
  },
  methods: {
    searchSigns: function() {
      this.$store.dispatch("searchSigns", this.search);
    }
  },
  created() {
    this.$store.dispatch("searchSigns", "");
  }
};
</script>

<style scoped lang="css">
#searchbox {
  margin-left: 20px;
  height: 100%;
  max-height: 48px;
  background-color: #f8f8f8;
  border: 1px solid gainsboro;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
}
@media screen and (max-width: 600px) {
  #searchbox {
    width: 100%;
    margin-left: 0;
  }
}
#searchbox input {
  height: 100%;
  width: 80%;
  padding: 12px;
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  border: none;
  background-color: transparent;
}
#searchbox input:focus {
  outline: none;
}
@media screen and (max-width: 600px) {
  #searchbox input {
    padding: 4px 12px;
  }
}
#searchbox .icon-holder {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 10px;
}
#searchbox .icon-holder img {
  max-height: 35px;
}
@media screen and (max-width: 600px) {
  #searchbox .icon-holder img {
    height: 40%;
    max-height: 40%;
  }
}
</style>
