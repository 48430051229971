<template>
  <div id="navigator-btn">
    <button class="next-step" @click="goNext(currentRoute)" v-if="!editMode">
      {{ nextStepBtn }}
    </button>
    <button
      class="next-step"
      @click="
        $store.dispatch('updateTable');
        $router.push({ name: 'Cart' });
      "
      v-else
    >
      ULOŽIT ZMĚNY
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["nextStepBtn", "editMode"]),
    currentRoute() {
      return this.$route.path;
    }
  },
  methods: {
    goNext: function(currentRoute) {
      this.$store.dispatch("goNext", currentRoute);
    }
  }
};
</script>

<style scoped lang="css">
#navigator-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  width: 100%;
  height: 48px;
  margin-bottom: 15px;
}
#navigator-btn .next-step {
  background-color: #ffc400;
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 1.2rem;
  padding: 10px 10px;
  color: #542a01;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 13px;
  width: 40%;
  height: 100%;
}
@media screen and (max-width: 900px) {
  #navigator-btn .next-step {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 600px) {
  #navigator-btn .next-step {
    height: 50px;
    font-size: 1.1rem;
    height: 50px;
    width: 80%;
    padding: 5px 10px;
  }
}
</style>
