<template>
  <div id="cart">
    <FinishedModal v-show="showFinishedModal" />
    <h1>POPTÁVKOVÝ FORMULÁŘ</h1>
    <div class="container" v-if="selectedConfigurations.length > 0">
      <div class="configs">
        <h2 class="subheading">POPTÁVANÉ KONFIGURACE</h2>
        <div
          class="config-item"
          v-for="(item, index) in selectedConfigurations"
          :key="index"
        >
          <div class="row">
            <div class="item-toggle">
              <img
                src="@/assets/icons/toggle.png"
                alt="toggle-img"
                @click="item.isToggled = !item.isToggled"
                :class="{ rotateToggle: item.isToggled }"
              />
            </div>
            <div class="item-thumbnail">
              <img :src="item.title_image" alt="obrázek" />
            </div>
            <div class="item-name">
              <span>{{ item.name }}</span>
            </div>
            <div class="quantity item item-quantity">
              <div
                class="minus"
                @click="
                  $store.dispatch('changeQuantityOfSelected', {
                    value: -1,
                    index: index
                  })
                "
              >
                <img
                  class="symbol-img"
                  src="@/assets/icons/minus.png"
                  alt="minus-btn"
                />
              </div>
              <input
                class="quantity-input"
                min="0"
                type="number"
                :value="[[selectedConfigurations[index].quantity]]"
                @change="event => setCartQuantity(event, index)"
              />
              <div
                class="plus"
                @click="
                  $store.dispatch('changeQuantityOfSelected', {
                    value: 1,
                    index: index
                  })
                "
              >
                <img
                  class="symbol-img"
                  src="@/assets/icons/plus.png"
                  alt="plus-btn"
                />
              </div>
            </div>
            <div class="item-edit">
              <img
                src="@/assets/icons/edit.png"
                alt="edit-icon"
                @click="
                  $store.dispatch('toggleEditMode', {
                    item: item,
                    index: index
                  });
                  /* TODO: přidat id tabule do params */
                  $router.push({ name: 'BoardConfig' });
                "
              />
              <img
                src="@/assets/icons/trash.png"
                alt="trash-icon"
                @click="$store.dispatch('removeConfiguration', index)"
              />
            </div>
          </div>
          <div class="description" :class="{ isOpen: item.isToggled }">
            <div
              class="side-detail"
              v-for="(signSet, index) in item.selectedSigns"
              :key="index"
            >
              <span class="side-heading" v-if="item.selectedSigns.length > 1"
                >Tabule {{ index + 1 }}</span
              >
              <span class="side-heading" v-if="item.selectedSigns.length === 1"
                >Tabule</span
              >
              <span v-for="(sign, index2) in signSet" :key="index2">{{
                sign.name
              }}</span>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div class="form-wrapper">
        <!-- Sudo metoda pro plnění formuláře (dvojklikem na nadpis se naplní formulář) -->
        <!--<h2 class="subheading" @dblclick="fillForm">KONTAKTNÍ ÚDAJE</h2>-->
        <h2 class="subheading">KONTAKTNÍ ÚDAJE</h2>
        <form id="customer-info" @submit.prevent="submit()">
          <p>
            <label for="name">Jméno*</label>
            <input
              type="text"
              id="name"
              v-model="customer.name"
              name="name"
              required
            />
          </p>

          <p>
            <label for="surname">Příjmení*</label>
            <input
              type="text"
              id="surname"
              v-model="customer.surname"
              name="surname"
              required
            />
          </p>

          <p>
            <label for="company">Společnost</label>
            <input
              type="text"
              id="company"
              v-model="customer.company"
              name="company"
            />
          </p>

          <p>
            <label for="ic">IČ</label>
            <input type="number" id="ic" v-model="customer.ic" name="ic" />
          </p>

          <p>
            <label for="dic">DIČ</label>
            <input type="text" id="dic" v-model="customer.dic" name="dic" />
          </p>

          <p></p>

          <p>
            <label for="telephone">Telefon*</label>
            <input
              type="tel"
              id="telephone"
              v-model="customer.telephone"
              name="telephone"
              required
            />
          </p>

          <p>
            <label for="email">Email*</label>
            <input
              type="email"
              id="email"
              v-model="customer.email"
              name="email"
              required
            />
          </p>

          <p>
            <label for="city">Město*</label>
            <input
              type="text"
              id="city"
              v-model="customer.city"
              name="city"
              required
            />
          </p>

          <p>
            <label for="psc">PSČ*</label>
            <input
              type="number"
              id="psc"
              v-model="customer.psc"
              name="psc"
              required
            />
          </p>

          <p>
            <label for="street">Ulice, číslo popisné*</label>
            <input
              type="text"
              id="street"
              v-model="customer.street"
              name="street"
              required
            />
          </p>
        </form>
        <p class="note-wrapper">
          <label for="note">Poznámka</label>
          <textarea
            rows="10"
            cols="10"
            id="note"
            v-model="customer.note"
            name="note"
          ></textarea>
        </p>
      </div>
    </div>
    <button
      form="customer-info"
      type="submit"
      v-if="selectedConfigurations.length > 0"
      class="submit-btn"
      :disabled="isLoading"
    >
      <img
        :class="{ hidden: !isLoading }"
        src="@/assets/icons/loading.png"
        alt=""
      />
      ODESLAT POPTÁVKU
    </button>
    <div class="empty-cart" v-if="selectedConfigurations.length === 0">
      <span>
        Neprovedli jste zatím žádné konfigurace
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FinishedModal from "../components/FinishedModal";
import TableServices from "../services/TableServices";
import NProgress from "nprogress";
import { api } from "../store/modules/api.module";
import store from "../store/store";

export default {
  name: "Cart",
  components: {
    FinishedModal
  },
  data() {
    return {
      showFinishedModal: false,
      customer: {
        name: "",
        surname: "",
        company: "",
        ic: "",
        dic: "",
        telephone: "",
        email: "",
        city: "",
        psc: "",
        street: "",
        note: ""
      },
      output: null
    };
  },
  computed: {
    ...mapState(["selectedConfigurations", "isLoading"])
  },
  async beforeRouteEnter(routeTo, routeFrom, next) {
    NProgress.start();
    if (api.state.tables.length && api.state.allSigns.length) {
      NProgress.done();
      next();
    } else {
      await store.dispatch("api/fetchCategories");
      store.dispatch("api/fetchBoards").then(() => {
        NProgress.done();
        next();
      });
    }
  },
  methods: {
    setCartQuantity(event, index) {
      if (event.target.valueAsNumber !== 0) {
        this.$store.dispatch("setCartQuantity", {
          value: event.target.valueAsNumber,
          index: index
        });
      }
    },
    /* metoda plnění formuláře */
    fillForm() {
      this.customer.name = "Filip";
      this.customer.surname = "Páral";
      this.customer.company = "Shean";
      this.customer.ic = "123456789";
      this.customer.dic = "CZE123456789";
      this.customer.telephone = "123456789";
      this.customer.email = "paral.shean@gmail.com";
      this.customer.city = "Blansko";
      this.customer.psc = "11111";
      this.customer.street = "Bezručova";
      this.customer.note = "Hello from Vue.js";
    },
    submit() {
      return new Promise(resolve => {
        const formdata = new FormData();

        formdata.append("name", this.customer.name);
        formdata.append("surname", this.customer.surname);
        formdata.append("company", this.customer.company);
        formdata.append("ic", this.customer.ic);
        formdata.append("dic", this.customer.dic);
        formdata.append("phone", this.customer.telephone);
        formdata.append("email", this.customer.email);
        formdata.append("city", this.customer.city);
        formdata.append("psc", this.customer.psc);
        formdata.append("street", this.customer.street);
        formdata.append("note", this.customer.note);
        formdata.append(
          "configurations",
          JSON.stringify(this.selectedConfigurations)
        );

        TableServices.newOrder(formdata)
          .then(response => {
            this.showFinishedModal = true;
            localStorage.removeItem("cart");
            resolve(response);
            setTimeout(() => {
              window.location.href = "https://www.lesnisvet.cz/";
            }, 2000);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  }
};
</script>

<style scoped lang="css">
.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px !important;
}

.hidden {
  display: none !important;
}

.submit-btn img {
  margin-right: 10px;
  animation: spin 1.3s linear infinite;
  height: 40px;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

#cart {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

#cart h1 {
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 2.1rem;
  text-align: center;
  color: #542a01;
  margin-bottom: 20px;
  margin-top: 15px;
}

@media screen and (max-width: 1200px) {
  #cart h1 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 900px) {
  #cart h1 {
    margin-bottom: 10px;
    margin-top: 8px;
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 600px) {
  #cart h1 {
    font-size: 1.3rem;
    margin-top: 15px;
    margin-bottom: 20px;
  }
}

#cart .container {
  max-width: 1200px;
}

@media screen and (max-width: 1200px) {
  #cart .container {
    max-width: 900px;
  }
}

@media screen and (max-width: 900px) {
  #cart .container {
    max-width: 600px;
  }
}

@media screen and (max-width: 600px) {
  #cart .container {
    max-width: 100vw;
  }
}

#cart .container .configs {
  width: 100%;
}

@media screen and (max-width: 600px) {
  #cart .container .configs {
    padding: 0 10px;
  }
}

#cart .container .configs .config-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

#cart .container .configs .config-item .row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

#cart .container .configs .config-item .row .item-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5%;
}

#cart .container .configs .config-item .row .item-toggle img {
  cursor: pointer;
  transform: rotateZ(-90deg);
  width: 40px;
  transition: all 0.2s ease-in;
}

@media screen and (max-width: 600px) {
  #cart .container .configs .config-item .row .item-toggle img {
    width: 25px;
  }
}

#cart .container .configs .config-item .row .item-thumbnail {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  #cart .container .configs .config-item .row .item-thumbnail {
    display: none;
  }
}

#cart .container .configs .config-item .row .item-thumbnail img {
  max-width: 90%;
}

@media screen and (max-width: 900px) {
  #cart .container .configs .config-item .row .item-thumbnail img {
    min-width: 70%;
  }
}

@media screen and (max-width: 600px) {
  #cart .container .configs .config-item .row .item-thumbnail img {
    display: none;
  }
}

#cart .container .configs .config-item .row .item-name {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}

#cart .container .configs .config-item .row .item-name span {
  text-transform: uppercase;
  font-family: "PT Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  color: #542a01;
}

@media screen and (max-width: 900px) {
  #cart .container .configs .config-item .row .item-name span {
    font-size: 1.2rem;
    white-space: nowrap;
    margin-left: 5px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 600px) {
  #cart .container .configs .config-item .row .item-name span {
    margin-left: 10px;
    font-size: 1rem;
  }
}

#cart .container .configs .config-item .row .item-quantity {
  height: 100%;
  font-family: "PT Sans", sans-serif;
  font-size: 1rem;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  #cart .container .configs .config-item .row .item-quantity {
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  #cart .container .configs .config-item .row .item-quantity {
    width: auto;
    margin-left: 50px;
  }
}

#cart .container .configs .config-item .row .item-quantity .symbol-img {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 900px) {
  #cart .container .configs .config-item .row .item-quantity .symbol-img {
    width: 20px;
    height: 20px;
  }
}

#cart .container .configs .config-item .row .item-quantity .minus,
#cart .container .configs .config-item .row .item-quantity .plus {
  background-color: #ffc400;
  border-radius: 50%;
  padding: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
}

@media screen and (max-width: 900px) {
  #cart .container .configs .config-item .row .item-quantity .minus,
  #cart .container .configs .config-item .row .item-quantity .plus {
    width: 45px;
    height: 45px;
    margin: 10px 20px;
  }
}

@media screen and (max-width: 600px) {
  #cart .container .configs .config-item .row .item-quantity .minus,
  #cart .container .configs .config-item .row .item-quantity .plus {
    display: none;
    margin: 10px 8px;
    width: 35px;
    height: 35px;
  }
}

#cart .container .configs .config-item .row .item-quantity .quantity-input {
  font-family: "PT Sans", sans-serif;
  font-size: 0.9rem;
  text-align: center;
  border: 1px solid #777;
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 900px) {
  #cart .container .configs .config-item .row .item-quantity .quantity-input {
    width: 50px;
    height: 50px;
    font-size: 1.1rem;
  }
}

#cart .container .configs .config-item .row .item-edit {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  #cart .container .configs .config-item .row .item-edit {
    flex-flow: column nowrap;
  }
}

#cart .container .configs .config-item .row .item-edit img {
  width: 30px;
  cursor: pointer;
  margin-left: 30px;
}

@media screen and (max-width: 600px) {
  #cart .container .configs .config-item .row .item-edit img {
    margin-left: 10px;
  }
}

#cart .container .configs .config-item .description {
  max-height: 0;
  width: 100%;
  padding-left: 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow: hidden;
  transition: 0.2s ease-in all;
}

@media screen and (max-width: 900px) {
  #cart .container .configs .config-item .description {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  #cart .container .configs .config-item .description {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 20px;
  }
}

#cart .container .configs .config-item .description .side-detail {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 30px;
}

@media screen and (max-width: 600px) {
  #cart .container .configs .config-item .description .side-detail {
    padding: 0px 10px 20px;
  }
}

#cart .container .configs .config-item .description .side-detail .side-heading {
  font-weight: bold;
  margin-bottom: 3px;
}

#cart .container .configs .config-item .description .side-detail span {
  font-family: "PT Sans", sans-serif;
  font-size: 1.1rem;
  line-height: 1.3rem;
}

#cart .container .configs .config-item hr {
  width: 100%;
  border: 1px solid #888;
}

#cart .container .form-wrapper {
  margin-bottom: 20px;
}

#cart .container .form-wrapper p label {
  display: block;
  font-family: "PT Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
}

#cart .container .form-wrapper p input {
  padding: 6px 15px;
  font-family: "PT Sans", sans-serif;
  font-size: 1.2rem;
  width: 400px;
  border: 1px solid #888;
  border-radius: 5px;
}

#cart .container .form-wrapper p input:focus {
  outline: none;
}

#cart .container .form-wrapper #customer-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 20px;
  max-width: 1200px;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  #cart .container .form-wrapper #customer-info {
    grid-template-columns: 1fr;
    column-gap: 30px;
    row-gap: 20px;
    max-width: 1200px;
    justify-items: center;
  }
}

#cart .container .form-wrapper #customer-info p input {
  width: 500px;
}

@media screen and (max-width: 1200px) {
  #cart .container .form-wrapper #customer-info p input {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  #cart .container .form-wrapper #customer-info p input {
    width: 95vw;
  }
}

#cart .submit-btn {
  background-color: #ffc400;
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 1.2rem;
  padding: 10px 10px;
  color: #542a01;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 13px;
  width: 400px;
  height: 100%;
  margin-bottom: 40px;
}

@media screen and (max-width: 900px) {
  #cart .submit-btn {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 600px) {
  #cart .submit-btn {
    height: 50px;
    font-size: 1.1rem;
    height: 50px;
    width: 80%;
    padding: 5px 10px;
  }
}

#cart .empty-cart {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#cart .empty-cart span {
  font-family: "PT Sans", sans-serif;
  font-size: 1.2rem;
}

@media screen and (max-width: 600px) {
  .note-wrapper label {
    margin-left: 2.5vw;
  }
}

.note-wrapper #note {
  width: 100%;
  overflow-y: scroll;
  padding: 6px 15px;
  font-family: "PT Sans", sans-serif;
  font-size: 1.1rem;
  line-height: 1.2rem;
  border: 1px solid #888;
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .note-wrapper #note {
    margin: 0 2.5vw;
    width: 95vw;
  }
}

.note-wrapper #note:focus {
  outline: none;
}

.subheading {
  font-family: "PT Sans", sans-serif;
  font-size: 1.8rem;
  text-align: left;
  margin-bottom: 1.2rem;
}

@media screen and (max-width: 600px) {
  .subheading {
    font-size: 1.2rem;
    text-align: center;
  }
}

.isOpen {
  max-height: 1600px !important;
}

.rotateToggle {
  transform: rotateZ(0deg) !important;
}
</style>
