<template>
  <div id="select-modal">
    <transition name="fade" appear>
      <div class="modal-overlay" @click="$emit('toggleCardBackModal')"></div>
    </transition>
    <transition name="pop">
      <div class="modal">
        <div class="modal-heading">
          <a href="#" class="spacer"></a>
          <h1 class="main-heading">VYBERTE RUB KARTY PEXESA</h1>
          <img
            @click="$emit('toggleCardBackModal')"
            src="@/assets/icons/delete.png"
            alt="X"
            class="close-modal-icon"
          />
        </div>
        <div class="wrapper">
          <div class="card-grid">
            <div class="card" v-for="cardback in cardBacks" :key="cardback.id">
              <div class="relative-container">
                <img
                  src="@/assets/icons/checkmark-60.png"
                  class="added-icon"
                  alt="replace-icon"
                  v-if="
                    tables[selectedTable].selectedCardback ===
                      cardback.id
                  "
                />
                <img
                  src="@/assets/icons/plus-math-brown-60.png"
                  class="replace-icon"
                  alt="replace-icon"
                  @click="changeCardBack(cardback)"
                  v-else
                />
                <div class="card-content">
                  <div class="front">
                    <img :src="cardback.cover_img" alt="cardback" />
                  </div>
                </div>
              </div>
              <div class="card-description">
                <p class="absolute-txt">{{ cardback.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CardBackModal",
  computed: {
    ...mapState(["selectedTable"]),
    ...mapState("api", ["cardBacks", "tables"])
  },
  methods: {
    changeCardBack(cardback) {
      this.$store.dispatch("api/changeCardBack", {
        cardback_id: cardback.id,
        board_index: this.selectedTable
      });
      this.$emit("toggleCardBackModal");
    },
    showCheckmark(cardback) {
      if (
        this.tables[this.selectedTable].selectedCardback ===
        cardback.id
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.close-modal-icon,
.spacer {
  width: 35px;
}

.close-modal-icon {
  cursor: pointer;
}

.modal-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#select-modal .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background: rgba(0, 0, 0, 0.3);
}
#select-modal .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 95;
  width: 80vw;
  min-height: 600px;
  max-height: 900px;
  max-width: 1000px;
  background: #fff;
  border-radius: 10px;
  padding: 16px;
}
@media screen and (max-width: 1200px) {
  #select-modal .modal {
    width: 80vw;
  }
}
@media screen and (max-width: 900px) {
  #select-modal .modal {
    min-height: 98vw;
    max-height: 98vh;
    width: 96vw;
    height: 100%;
  }
}
#select-modal .modal .main-heading {
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 2.2rem;
  text-align: center;
  color: #542a01;
  margin-bottom: 20px;
  margin-top: 10px;
}
@media screen and (max-width: 900px) {
  #select-modal .modal .main-heading {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 600px) {
  #select-modal .modal .main-heading {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    margin-top: 8px;
  }

  #select-modal .modal {
    min-height: 98vw;
    max-height: 98vh;
  }

  #select-modal .modal .wrapper {
    overflow-y: scroll;
  }

  #select-modal .modal .wrapper .toolbar {
    height: auto;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  #select-modal .modal .wrapper .card-grid {
    grid-template-columns: repeat(3, 1fr);
    padding-right: 20px;
    overflow-y: visible;
  }

  #select-modal .modal .wrapper .card-grid .card .relative-container {
    height: 85px;
    width: 95px;
  }

  #select-modal
    .modal
    .wrapper
    .card-grid
    .card
    .card-description
    .absolute-txt {
    position: absolute;
    line-height: 0.9rem;
    margin: auto;
    white-space: normal;
  }

  .dropdown {
    margin-left: 0 !important;
  }
}
#select-modal .modal .wrapper {
  overflow-y: auto;
}
#select-modal .modal .wrapper .toolbar {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: center;
  height: 50px;
  margin-bottom: 10px;
  overflow-y: visible;
}
#select-modal .modal .wrapper .toolbar .feedback {
  font-family: "PT Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: normal;
  position: absolute;
  bottom: -25px;
  color: red;
}
#select-modal .modal .wrapper .info-text {
  width: 100%;
  color: #5e646a;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
}
#select-modal .modal .wrapper .info-text p {
  text-align: center;
}
#select-modal .modal .wrapper .card-grid {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  padding: 0 15px;
  max-height: 65vh;
  justify-items: center;
  overflow-y: scroll;
  padding-top: 10px;
}
@media screen and (max-width: 900px) {
  #select-modal .modal .wrapper .card-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
#select-modal .modal .wrapper .card-grid .card {
  position: relative;
  min-height: 100px;
  margin: 0 8px 10px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}
#select-modal .modal .wrapper .card-grid .card .relative-container {
  position: relative;
  height: 90px;
  width: 100px;
  display: flex;
  justify-content: center;
}

#select-modal
  .modal
  .wrapper
  .card-grid
  .card
  .relative-container
  .replace-icon,
#select-modal .modal .wrapper .card-grid .card .relative-container .added-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  border: 2px solid #542a01;
  border-radius: 50%;
  z-index: 1;
  padding: 3px;
  width: 30px;
  height: 30px;
  background-color: #fff;
}
#select-modal
  .modal
  .wrapper
  .card-grid
  .card
  .relative-container
  .replace-icon {
  cursor: pointer;
}
#select-modal .modal .wrapper .card-grid .card .relative-container .added-icon {
  cursor: default;
}
#select-modal
  .modal
  .wrapper
  .card-grid
  .card
  .relative-container
  .card-content {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s;
  transform-style: preserve-3d;
}
#select-modal
  .modal
  .wrapper
  .card-grid
  .card
  .relative-container
  .card-content
  .no-repetitions {
  position: absolute;
  bottom: 5px;
  right: 8px;
  z-index: 100;
}
#select-modal
  .modal
  .wrapper
  .card-grid
  .card
  .relative-container
  .card-content
  .no-repetitions
  span {
  color: #5e646a;
  font-family: "PT Sans", sans-serif;
  font-size: 0.8rem;
  font-weight: 300;
}
#select-modal
  .modal
  .wrapper
  .card-grid
  .card
  .relative-container
  .card-content
  .front,
#select-modal
  .modal
  .wrapper
  .card-grid
  .card
  .relative-container
  .card-content
  .back {
  cursor: pointer;
  border: 2px solid #777;
  border-radius: 10px;
  display: block;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  background-color: #fff;
}
#select-modal
  .modal
  .wrapper
  .card-grid
  .card
  .relative-container
  .card-content
  .front
  img,
#select-modal
  .modal
  .wrapper
  .card-grid
  .card
  .relative-container
  .card-content
  .back
  img {
  max-height: 100%;
}
#select-modal
  .modal
  .wrapper
  .card-grid
  .card
  .relative-container
  .card-content
  .back {
  transform: rotateY(180deg);
}
#select-modal .modal .wrapper .card-grid .card .card-description {
  font-family: "PT Sans", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  width: 100%;
  position: relative;
  text-align: center;
  height: 35px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  #select-modal .modal .wrapper .card-grid .card .card-description {
    font-size: 0.9rem;
  }
}
#select-modal .modal .wrapper .card-grid .card .card-description .absolute-txt {
  position: absolute;
  line-height: 1.2rem;
  margin: auto;
  white-space: normal;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.pop-enter-active,
.pop-leave-active {
  transition: all 0.3s ease;
}
.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(1.1);
}
.flipped {
  transform: rotateY(180deg);
}
.greyedItem {
  filter: grayscale(100%);
  opacity: 0.7;
}
.yellowedItem {
  border: 2px solid #ffc400 !important;
}
.invisible {
  display: none;
}
.card-content.flipped {
  transform: rotateY(180deg);
}
.dropdown {
  position: relative;
  z-index: 150;
  margin-left: 14px;
}
</style>
