<template>
  <div id="navbar">
    <div class="logo">
      <router-link :to="{ name: 'BoardSelect' }">
        <img
          src="https://www.lesnisvet.cz/img/logo.svg"
          alt="lesni-svet-logo"
        />
      </router-link>
    </div>
    <div class="cart">
      <router-link :to="{ name: 'Cart' }">
        <img src="@/assets/icons/cart.png" alt="kosik" class="cart-icon" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar"
};
</script>

<style scoped lang="css">
#navbar {
  height: 92px;
  box-shadow: 0px 5px 11px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  padding-left: 53px;
}
@media screen and (max-width: 600px) {
  #navbar {
    height: 68px;
    padding-left: 15px;
  }
}
#navbar .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 150px;
}
@media screen and (max-width: 600px) {
  #navbar .logo {
    justify-content: flex-start;
  }
}
#navbar .logo a {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
}
#navbar .logo a img {
  max-height: 80%;
  max-width: 80%;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  #navbar .logo a img {
    max-width: 60%;
  }
}

#navbar .cart {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  width: 90px;
  background-color: #ffc400;
}
#navbar .cart a {
  display: flex;
  justify-content: center;
  align-items: center;
}
#navbar .cart a img {
  max-width: 80%;
  max-height: 80%;
}

@media screen and (max-width: 600px) {
  .cart-icon {
    width: 70px;
  }
}
</style>
