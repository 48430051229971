<template>
  <div id="instruction-modal">
    <transition name="fade" appear>
      <div class="modal-overlay" @click="$emit('closeModal')"></div>
    </transition>
    <transition name="pop">
      <div class="modal">
        <div class="wrapper">
          <h1 class="heading">Jak na to</h1>
          <div class="hint-container mobile-hint">
            <img
              class="swipe-hint-img"
              src="@/assets/icons/swipe-left.png"
              alt="swipe-left"
            />
            <p>
              Navigujte mezi sloupci tabule přejetím prstu do stran po tabuli.
            </p>
            <hr />
          </div>
          <div class="hint-container">
            <img
              class="add-hint-img"
              src="@/assets/icons/add-hint.png"
              alt="add-card"
            />
            <p>
              Klepnutím na ikonu + zobrazíte nabídku výběru pro danou kartu.
            </p>
            <hr />
          </div>
          <div class="hint-container">
            <img
              class="turn-hint-img"
              src="@/assets/icons/turn-hint.png"
              alt="turn-card"
            />
            <p>
              Zobrazte zadní stranu karty klepnutím na její obrázek.<br />Přidejte
              poté kartu do tabule klepnutím na tlačítko + v rohu karty.
            </p>
          </div>
          <div v-if="!$store.state.pexesoMode" class="hint-container">
            <img
              class="used-hint-img"
              src="@/assets/icons/used-item.png"
              alt="used-card"
            />
            <p>
              Pokud je možné na tabuli umístit více tabulek<br />
              je počet použití tabulky zobrazen v pravém spodním rohu karty.
            </p>
          </div>
          <div v-else class="hint-container">
            <img
              class="used-hint-img"
              src="@/assets/icons/first-card-pexeso.png"
              alt="used-card"
            />
            <p>
              Pozice první karta je označena touto ikonou<br />
              umístěte následně druhou kartu do kteréhokoliv volného pole
            </p>
          </div>
          <button @click="$emit('closeModal')">Rozumím</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "InstructionModal"
};
</script>

<style scoped lang="css">
#instruction-modal .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background: rgba(0, 0, 0, 0.3);
}
#instruction-modal .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  z-index: 95;
  max-height: 90vh;
  background: #fff;
  border-radius: 10px;
  padding: 15px 15px;
  overflow-y: scroll;
}
@media screen and (max-width: 1200px) {
  #instruction-modal .modal {
    width: 70vw;
  }
}
@media screen and (max-width: 900px) {
  #instruction-modal .modal {
    width: 80vw;
  }
}
@media screen and (max-width: 600px) {
  #instruction-modal .modal {
    max-height: 95vh;
    width: 90vw;
  }
}
#instruction-modal .modal .wrapper {
  margin: 10px 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}
#instruction-modal .modal .wrapper .heading {
  font-family: "kg_life_is_messy", sans-serif;
  text-align: center;
  font-size: 2.4rem;
  color: #542a01;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 900px) {
  #instruction-modal .modal .wrapper .heading {
    margin-bottom: 1.5rem;
    font-size: 2rem;
  }
}
@media screen and (max-width: 600px) {
  #instruction-modal .modal .wrapper .heading {
    font-size: 1.7rem;
  }
}
#instruction-modal .modal .wrapper .hint-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}
#instruction-modal .modal .wrapper .hint-container .swipe-hint-img {
  max-width: 50px;
}
#instruction-modal .modal .wrapper .hint-container .turn-hint-img {
  width: 290px;
}
@media screen and (max-width: 600px) {
  #instruction-modal .modal .wrapper .hint-container .turn-hint-img {
    width: 220px;
  }
}
#instruction-modal .modal .wrapper .hint-container .add-hint-img {
  max-width: 92px;
}
@media screen and (max-width: 600px) {
  #instruction-modal .modal .wrapper .hint-container .add-hint-img {
    max-width: 80px;
  }
}
#instruction-modal .modal .wrapper .hint-container .used-hint-img {
  max-width: 112px;
}
@media screen and (max-width: 600px) {
  #instruction-modal .modal .wrapper .hint-container .used-hint-img {
    max-width: 102px;
  }
}
#instruction-modal .modal .wrapper .hint-container p {
  text-align: center;
  font-family: "PT Sans", sans-serif;
  font-size: 1.1rem;
  margin: 15px 0;
}
@media screen and (max-width: 600px) {
  #instruction-modal .modal .wrapper .hint-container p {
    font-size: 0.9rem;
  }
}
#instruction-modal .modal .wrapper .hint-container hr {
  border-top: 0.5px solid #777;
  border-bottom: none;
  width: 60%;
  text-align: center;
  margin: 10px auto 20px;
}
@media screen and (max-width: 600px) {
  #instruction-modal .modal .wrapper .hint-container hr {
    width: 80%;
    margin: 0 auto 15px;
  }
}
@media screen and (min-width: 600px) {
  #instruction-modal .modal .wrapper .mobile-hint {
    display: none;
  }
}
#instruction-modal .modal .wrapper button {
  margin: 10px auto 20px;
  background-color: #ffc400;
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 1.4rem;
  padding: 10px 10px;
  color: #542a01;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 13px;
  width: 250px;
}
@media screen and (max-width: 600px) {
  #instruction-modal .modal .wrapper button {
    width: 70%;
    font-size: 1.2rem;
    margin: 0 auto 20px;
    padding: 5px 10px;
  }
}
</style>
