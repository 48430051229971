import axios from "axios";
import store from "../store/store";

const apiClient = axios.create({
  // baseURL: "http://localhost/api",
  baseURL: "https://konfigurator.lesnisvet.cz/api",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

// toggleLoading

apiClient.interceptors.request.use(config => {
  store.dispatch("toggleLoading", true);
  return config;
});
apiClient.interceptors.response.use(response => {
  store.dispatch("toggleLoading", false);
  return response;
});

export default {
  getBoards() {
    return apiClient.get("/boards");
  },
  getCategories() {
    return apiClient.get("/categories");
  },
  getSigns(id) {
    return apiClient.get("/category_signs/" + id);
  },
  getCardBacks() {
    return apiClient.get("/cardbacks");
  },
  newOrder(data) {
    return apiClient.post("/order", data);
  }
};
