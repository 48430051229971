<template>
  <div id="product-config">
    <SelectModal
      v-show="showSelectModal"
      @toggleSelectModal="showSelectModal = !showSelectModal"
      @selectSecondPair="togglePexesoSelect($event)"
      :card-to-change="cardToChange"
      :side="side"
      :pexeso-mode="pexesoMode"
    ></SelectModal>
    <!-- modal pro vyber rubu karty (pexeso) -->
    <CardBackModal
      v-if="showCardBackModal"
      @toggleCardBackModal="showCardBackModal = !showCardBackModal"
      v-prevent-parent-scroll
    ></CardBackModal>
    <InstructionModal
      class="instruction-modal"
      v-show="showInstModal"
      @closeModal="showInstModal = false"
      v-prevent-parent-scroll
    ></InstructionModal>
    <Navigator></Navigator>
    <div class="wrapper" ref="wrapperBox">
      <div class="table">
        <div class="cube" :class="getClassName()">
          <div
            class="space-creator"
            :style="{
              width: +tableWidth + 'px',
              height: +tableHeight + 'px'
            }"
          ></div>
          <template>
            <div
              v-for="i in tables[selectedTable].tables"
              :key="i"
              :class="getSideClass(i)"
              id="target-table"
              class="selected-table"
              :style="{
                'grid-template-columns':
                  'repeat(' + tables[selectedTable].columns + ', 1fr)',
                'grid-template-rows':
                  'repeat(' + tables[selectedTable].rows + ', 1fr)'
              }"
              ref="innerTable"
            >
              <div
                class="card"
                v-for="(item, index) in tables[selectedTable]
                  .selectedSigns[side]"
                :key="index"
              >
                <span
                  class="col-num"
                  v-if="index + 1 <= tables[selectedTable].columns"
                  >{{ index + 1 }}</span
                >
                <div class="relative-container">
                  <img
                    v-if="item.name !== ''"
                    :src="
                      !pexesoMode
                        ? '/conf/images/plus-math-brown-60.png'
                        : '/conf/images/delete.png'
                    "
                    class="replace-icon"
                    alt="replace-icon"
                    @click="cardAction(index)"
                  />
                  <div
                    class="card-content"
                    :class="{ flipped: index === flippedCard }"
                  >
                    <div
                      class="front"
                      :style="[
                        pexesoFrontCard &&
                        index !== pexesoFrontCard.cardToChange &&
                        pexesoSelectMode
                          ? { border: '2px dashed #777' }
                          : { border: '2px solid #777' }
                      ]"
                      @click="cardHandler(index, item)"
                      :class="[
                        {
                          'empty-sign-warn':
                            item.name === '' &&
                            tables[selectedTable].highlightEmpty ===
                              true
                        }
                      ]"
                    >
                      <img :src="getFrontImage(index, item)" alt="item.name" />
                    </div>
                    <div class="back" @click="cardHandler(index, item)">
                      <img :src="item.back_image" alt="item.name" />
                    </div>
                  </div>
                </div>
                <div class="card-description">
                  <p class="absolute-txt">{{ item.name }}</p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- Sudo metoda pro plnění tabulky (dvojklikem na stojnu se naplní tabule tabulkami) -->
      <!--<div class="left-leg" @dblclick="$store.dispatch('sudo/fillTable')"></div>-->
      <div class="left-leg"></div>
      <div class="right-leg"></div>
      <div class="sign-name">
        <h1 class="table-heading">{{ tables[selectedTable].name }}</h1>
      </div>
      <div class="traverse">
        <div
          class="table-switch"
          v-if="tables[selectedTable].tables != 1"
        >
          <img
            src="@/assets/icons/previous-arrow-100.png"
            alt="previous-table-img"
            @click="prevSide()"
          />
          <span>{{ "TABULE " + (side + 1) }}</span>
          <img
            class="sideButton"
            src="@/assets/icons/next-arrow-100.png"
            alt="previous-table-img"
            @click="nextSide()"
          />
        </div>
      </div>
      <div class="roof">
        <img
          src="@/assets/icons/trash_can.png"
          alt="info-icon"
          @click="$store.dispatch('api/purgeConfiguration')"
        />
        <img
          v-if="pexesoMode"
          src="@/assets/icons/card-back.png"
          alt="info-icon"
          @click="!pexesoSelectMode ? (showCardBackModal = true) : ''"
        />
        <img
          src="@/assets/icons/info-100.png"
          alt="info-icon"
          @click="!pexesoSelectMode ? (showInstModal = true) : ''"
        />
      </div>
      <div class="bottom"></div>
    </div>
    <NavigatorBtn class="navigator-btn"></NavigatorBtn>
  </div>
</template>

<script>
import Navigator from "@/components/Navigator";
import SelectModal from "@/components/SelectModal";
import NavigatorBtn from "@/components/NavigatorBtn";
import InstructionModal from "@/components/InstructionModal";
import { mapState } from "vuex";
import NProgress from "nprogress";
import { api } from "../store/modules/api.module";
import CardBackModal from "../components/CardBackModal";
import store from "../store/store";

export default {
  name: "BoardConfig",
  components: {
    CardBackModal,
    Navigator: Navigator,
    SelectModal: SelectModal,
    NavigatorBtn: NavigatorBtn,
    InstructionModal: InstructionModal
  },
  data() {
    return {
      tableWidth: null,
      tableHeight: null,
      showSelectModal: false,
      cardToChange: null,
      flippedCard: null,
      side: 0,
      showInstModal: false,
      showCardBackModal: false,
      /* data - pexeso funkcionalita */
      pexesoFrontCard: null,
      pexesoSelectMode: false
    };
  },
  computed: {
    ...mapState(["highlightEmptySigns", "currentPosition", "pexesoMode", "selectedTable"]),
    ...mapState("api", ["tables", "signCategories"])
  },
  methods: {
    cardAction(index) {
      if (!this.pexesoMode) {
        this.showSelectModal = true;
        this.cardToChange = index;
      } else {
        const pairCardIndex = this.tables[this.selectedTable]
          .selectedSigns[this.side][index].pair_card_index;
        this.$store.dispatch("api/changeCard", {
          sign: {
            item_id: "empty" + pairCardIndex,
            name: "",
            front_image: "/conf/images/empty.png",
            back_image: "/conf/images/empty.png"
          },
          cardToChange: pairCardIndex,
          side: this.side,
          selectedTable: this.selectedTable
        });

        this.$store.dispatch("api/changeCard", {
          sign: {
            item_id: "empty" + index,
            name: "",
            front_image: "/conf/images/empty.png",
            back_image: "/conf/images/empty.png"
          },
          cardToChange: index,
          side: this.side,
          selectedTable: this.selectedTable
        });
      }
    },
    setPexesoPair(index) {
      const frontImage = { ...this.pexesoFrontCard.sign };
      const backImage = { ...this.pexesoFrontCard.sign };
      frontImage["back_image"] = "";
      frontImage["back_img_name"] = "";
      frontImage["pair_card_index"] = index;
      backImage["front_image"] = backImage["back_image"];
      backImage["front_img_name"] = backImage["back_img_name"];
      backImage["pair_card_index"] = this.pexesoFrontCard.cardToChange;
      backImage["back_image"] = "";
      backImage["back_img_name"] = "";

      /* umisteni zadni strany */
      this.$store.dispatch("api/changeCard", {
        sign: backImage,
        cardToChange: index,
        side: this.side,
        selectedTable: this.selectedTable
      });
      /* umisteni predni strany */
      this.$store.dispatch("api/changeCard", {
        sign: frontImage,
        cardToChange: this.pexesoFrontCard.cardToChange,
        side: this.pexesoFrontCard.side,
        selectedTable: this.pexesoFrontCard.selectedTable
      });

      this.pexesoFrontCard = null;
      this.pexesoSelectMode = false;
    },
    getFrontImage(index, item) {
      if (
        this.pexesoMode &&
        this.pexesoFrontCard &&
        this.pexesoFrontCard.cardToChange === index
      ) {
        return "/conf/images/checklist.png";
      } else {
        if (item.name === "") {
          return "/conf/images/empty.png";
        } else {
          return item.front_image;
        }
      }
    },
    togglePexesoSelect(frontSignData) {
      this.pexesoSelectMode = true;
      this.pexesoFrontCard = frontSignData;
      this.showSelectModal = false;
    },
    getClassName() {
      return "item" + this.side;
    },
    getSideClass(i) {
      return "side" + i;
    },
    prevSide() {
      if (this.side === 0) {
        this.side = 3;
      } else {
        --this.side;
      }
    },
    nextSide() {
      if (this.side === 3) {
        this.side = 0;
      } else {
        ++this.side;
      }
    },
    cardHandler: function(index, item) {
      if (!this.pexesoSelectMode) {
        if (item.name === "") {
          this.showSelectModal = true;
          this.cardToChange = index;
        } else if (!this.pexesoMode) {
          if (this.flippedCard === index) {
            this.flippedCard = null;
          } else {
            this.flippedCard = index;
          }
        }
      } else if (
        this.pexesoFrontCard &&
        index !== this.pexesoFrontCard.cardToChange &&
        this.tables[this.selectedTable].selectedSigns[this.side][index]
          .name === ""
      ) {
        this.setPexesoPair(index, item);
      }
    }
  },
  async beforeRouteEnter(routeTo, routeFrom, next) {
    NProgress.start();
    if (api.state.tables.length && api.state.allSigns.length) {
      NProgress.done();
      next();
    } else {
      await store.dispatch("api/fetchCategories");
      store.dispatch("api/fetchBoards").then(() => {
        NProgress.done();
        next();
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("disableEditMode");
    next();
  },
  created() {
    this.$store.dispatch("updateNavBtn", ["SOUHRN KONFIGURACE", 2]);
  },
  mounted() {
    this.tableWidth = this.$refs.innerTable[0].clientWidth;
    this.tableHeight = this.$refs.innerTable[0].clientHeight;
    this.$store.dispatch("setCurrentPos", 1);
  }
};
</script>

<style scoped lang="css">
#product-config .wrapper {
  max-width: 1200px;
  margin: 30px auto;
  max-height: 900px;
  display: grid;
  grid-template-columns: auto 30px 50px min-content 50px 30px auto;
  gap: 0;
  grid-template-areas: "spacer-left roof roof roof roof roof spacer-right" "spacer-left . left-leg sign-name right-leg . spacer-right" "spacer-left . left-leg table right-leg . spacer-right" "spacer-left . left-leg traverse right-leg . spacer-right" "spacer-left . left-leg bottom right-leg . spacer-right";
}

@media screen and (max-width: 600px) {
  #product-config .wrapper {
    margin: 15px auto;
    max-height: none;
    grid-template-columns: 5px 10px 30px 7fr 30px 10px 5px;
    grid-template-rows: 35px 50px min-content 40px auto;
  }

  #product-config .wrapper .sign-name .table-heading {
    font-size: 24px;
    margin: 7px 0;
  }
}
#product-config .wrapper .left-leg,
#product-config .wrapper .right-leg {
  background-color: #290d00;
  background-image: url(../assets/textures/purty-wood.png);
  width: 50px;
}

#product-config .wrapper .left-leg {
  grid-area: left-leg;
  justify-self: end;
}
#product-config .wrapper .right-leg {
  grid-area: right-leg;
  justify-self: start;
}
#product-config .wrapper .sign-name {
  grid-area: sign-name;
  height: 60px;
  background-color: #edd7ad;
  background-image: url(../assets/textures/wood-pattern.png);
}

#product-config .wrapper .sign-name .table-heading {
  font-family: "kg_life_is_messy", sans-serif;
  text-align: center;
  font-size: 24px;
  color: #542a01;
  margin: 10px 0;
}

@media screen and (max-width: 1200px) {
  .wrapper {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 900px) {
  #product-config .wrapper .roof {
    height: 40px;
  }

  #product-config .wrapper .roof img {
    height: 80%;
  }

  #product-config .wrapper .bottom {
    height: 100px;
    min-height: 80px;
  }

  #product-config .wrapper {
    margin: 15px auto;
    max-height: none;
    grid-template-columns: 5px 10px 30px 7fr 30px 10px 5px;
    grid-template-rows: 40px 50px min-content 40px auto;
  }

  #product-config .wrapper .left-leg,
  #product-config .wrapper .right-leg {
    width: 30px;
  }

  #product-config .wrapper .sign-name {
    height: 50px;
  }

  #product-config .wrapper .sign-name .table-heading {
    font-size: 28px;
    margin: 5px 0;
  }
}

#product-config .wrapper .traverse {
  grid-area: traverse;
  height: 50px;
  background-color: #290d00;
  background-image: url(../assets/textures/purty-wood-horizontal.png);
  display: flex;
  justify-content: center;
  align-items: center;
}
#product-config .wrapper .traverse .table-switch {
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#product-config .wrapper .traverse .table-switch img {
  max-width: 40px;
  margin: 0 30px;
}
#product-config .wrapper .traverse .table-switch span {
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 20px;
  color: #fff;
}
@media screen and (max-width: 600px) {
  #product-config .wrapper .traverse .table-switch span {
    font-size: 18px;
  }
}
#product-config .wrapper .roof {
  grid-area: roof;
  background-color: #290d00;
  background-image: url(../assets/textures/purty-wood-horizontal.png);
  height: 60px;
  border-radius: 3px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 600px) {
  #product-config .wrapper .roof {
    height: 35px;
  }
}
#product-config .wrapper .roof img {
  height: 70%;
  margin: 0 20px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  #product-config .wrapper .roof img {
    height: 80%;
  }
}
#product-config .wrapper .bottom {
  grid-area: bottom;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (max-width: 600px) {
  #product-config .wrapper .bottom {
    height: 80px;
    min-height: 60px;
  }
}
#product-config .wrapper .table {
  justify-self: center;
  padding: 20px 50px;
  grid-area: table;
  perspective: 900px;
}
@media screen and (max-width: 900px) {
  #product-config .wrapper .table {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 20px 15px 0px;
    perspective: 900px;
  }
}
@media screen and (max-width: 600px) {
  #product-config .wrapper .table {
    padding: 0px 10px;
  }
}
#product-config .wrapper .table .cube {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-50%);
  transition: transform 1s;
}
#product-config .wrapper .table .cube .selected-table {
  backface-visibility: hidden;
  background-color: white;
  display: grid;
  column-gap: 20px;
  row-gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 900px) {
  #product-config .wrapper .table .cube .selected-table {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    height: auto;
    column-gap: 10px;
    row-gap: 5px;
    padding-bottom: 10px;
  }
}
#product-config .wrapper .table .cube .selected-table .card {
  position: relative;
  min-height: 90px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}
@media screen and (max-width: 600px) {
  #product-config .wrapper .table .cube .selected-table .card {
    min-height: 70px;
    margin-top: 10px;
  }
}
#product-config .wrapper .table .cube .selected-table .card .col-num {
  display: none;
}
@media screen and (max-width: 900px) {
  #product-config .wrapper .table .cube .selected-table .card .col-num {
    display: block;
    font-family: "PT Sans", sans-serif;
    font-size: 1rem;
    line-height: 0.8rem;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 600px) {
  #product-config .wrapper .table .cube .selected-table .card .col-num {
    font-size: 0.9rem;
    line-height: 0.8rem;
    margin-bottom: 0px;
  }
}
#product-config
  .wrapper
  .table
  .cube
  .selected-table
  .card
  .relative-container {
  position: relative;
  height: 90px;
  width: 100px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  #product-config
    .wrapper
    .table
    .cube
    .selected-table
    .card
    .relative-container {
    height: 70px;
    width: 80px;
  }
}
#product-config
  .wrapper
  .table
  .cube
  .selected-table
  .card
  .relative-container
  .replace-icon {
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  border: 2px solid #542a01;
  border-radius: 50%;
  z-index: 2;
  padding: 3px;
  width: 30px;
  height: 30px;
  background-color: #fff;
}
@media screen and (max-width: 600px) {
  #product-config
    .wrapper
    .table
    .cube
    .selected-table
    .card
    .relative-container
    .replace-icon {
    height: 28px;
    width: 28px;
  }
}
#product-config
  .wrapper
  .table
  .cube
  .selected-table
  .card
  .relative-container
  .card-content {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s;
  transform-style: preserve-3d;
}
#product-config
  .wrapper
  .table
  .cube
  .selected-table
  .card
  .relative-container
  .card-content
  .front,
#product-config
  .wrapper
  .table
  .cube
  .selected-table
  .card
  .relative-container
  .card-content
  .back {
  cursor: pointer;
  border: 2px solid #777;
  border-radius: 10px;
  display: block;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  background-color: #fff;
}
#product-config
  .wrapper
  .table
  .cube
  .selected-table
  .card
  .relative-container
  .card-content
  .front
  img,
#product-config
  .wrapper
  .table
  .cube
  .selected-table
  .card
  .relative-container
  .card-content
  .back
  img {
  max-height: 100%;
}
#product-config
  .wrapper
  .table
  .cube
  .selected-table
  .card
  .relative-container
  .card-content
  .back {
  transform: rotateY(180deg);
}
#product-config .wrapper .table .cube .selected-table .card .card-description {
  font-family: "PT Sans", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  width: 100%;
  position: relative;
  text-align: center;
  height: 35px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  #product-config
    .wrapper
    .table
    .cube
    .selected-table
    .card
    .card-description {
    font-size: 0.9rem;
    height: 20px;
  }
}
#product-config
  .wrapper
  .table
  .cube
  .selected-table
  .card
  .card-description
  .absolute-txt {
  position: absolute;
  line-height: 1.2rem;
  margin: auto;
  white-space: normal;
}
@media screen and (max-width: 600px) {
  #product-config
    .wrapper
    .table
    .cube
    .selected-table
    .card
    .card-description
    .absolute-txt {
    position: absolute;
    line-height: 0.9rem;
    padding-top: 6px;
    margin: auto;
    white-space: normal;
  }
}
@media screen and (min-width: 1200px) {
  .navigator-btn {
    display: none !important;
  }
}
@media screen and (max-width: 900px) {
  .navigator-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 49;
  }
}
.sideButton {
  cursor: pointer;
}
.instruction-modal {
  position: fixed;
  z-index: 99;
  height: 90vh;
}
.card-content.flipped {
  transform: rotateY(180deg);
}
.empty-sign-warn {
  border: 2px solid #b33a3a !important;
  animation: shake 0.5s;
}
.item0 {
  transform: translateZ(-209px) rotateY(0deg);
}
@media screen and (max-width: 900px) {
  .item0 {
    transform: translateZ(-300px) rotateY(0deg);
  }
}
@media screen and (max-width: 600px) {
  .item0 {
    transform: translateZ(-150px) rotateY(0deg);
  }
}
.item1 {
  transform: translateZ(-209px) rotateY(-90deg);
}
@media screen and (max-width: 900px) {
  .item1 {
    transform: translateZ(-300px) rotateY(-90deg);
  }
}
@media screen and (max-width: 600px) {
  .item1 {
    transform: translateZ(-150px) rotateY(-90deg);
  }
}
.item2 {
  transform: translateZ(-209px) rotateY(-180deg);
}
@media screen and (max-width: 900px) {
  .item2 {
    transform: translateZ(-300px) rotateY(-180deg);
  }
}
@media screen and (max-width: 600px) {
  .item2 {
    transform: translateZ(-150px) rotateY(-180deg);
  }
}
.item3 {
  transform: translateZ(-209px) rotateY(90deg);
}
@media screen and (max-width: 900px) {
  .item3 {
    transform: translateZ(-300px) rotateY(90deg);
  }
}
@media screen and (max-width: 600px) {
  .item3 {
    transform: translateZ(-150px) rotateY(90deg);
  }
}
.side1 {
  transform: rotateY(0deg) translateZ(209px);
}
@media screen and (max-width: 900px) {
  .side1 {
    transform: rotateY(0deg) translateZ(300px);
  }
}
@media screen and (max-width: 600px) {
  .side1 {
    transform: rotateY(0deg) translateZ(150px);
  }
}
.side2 {
  transform: rotateY(90deg) translateZ(209px);
}
@media screen and (max-width: 900px) {
  .side2 {
    transform: rotateY(90deg) translateZ(300px);
  }
}
@media screen and (max-width: 600px) {
  .side2 {
    transform: rotateY(90deg) translateZ(150px);
  }
}
.side3 {
  transform: rotateY(180deg) translateZ(209px);
}
@media screen and (max-width: 900px) {
  .side3 {
    transform: rotateY(180deg) translateZ(300px);
  }
}
@media screen and (max-width: 600px) {
  .side3 {
    transform: rotateY(180deg) translateZ(150px);
  }
}
.side4 {
  transform: rotateY(-90deg) translateZ(209px);
}
@media screen and (max-width: 900px) {
  .side4 {
    transform: rotateY(-90deg) translateZ(300px);
  }
}
@media screen and (max-width: 600px) {
  .side4 {
    transform: rotateY(-90deg) translateZ(150px);
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
