<template>
  <div id="config-accepted">
    <transition name="fade" appear>
      <div class="modal-overlay" @click="$emit('closeModal')"></div>
    </transition>
    <transition name="pop">
      <div class="modal">
        <div class="heading">
          <img src="@/assets/icons/checkmark.png" alt="checkmark-img" />
          <span>Konfigurace přidána do poptávky.</span>
        </div>
        <div class="buttons">
          <button
            @click="$store.dispatch('toggleAddedModal', 'BoardSelect')"
            class="next-config-btn"
          >
            NAKONFIGUROVAT DALŠÍ TABULI
          </button>
          <button
            @click="$store.dispatch('toggleAddedModal', 'Cart')"
            class="finish-btn"
          >
            DOKONČIT POPTÁVKU
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ConfigAcceptedModal"
};
</script>

<style scoped lang="css">
#config-accepted {
  display: flex;
  justify-content: center;
  align-items: center;
}
#config-accepted .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background: rgba(0, 0, 0, 0.3);
}
#config-accepted .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  width: auto;
  z-index: 95;
  max-height: 90vh;
  background: #fff;
  border-radius: 10px;
  padding: 50px 50px;
}
@media screen and (max-width: 1200px) {
  #config-accepted .modal {
    width: auto;
  }
}
@media screen and (max-width: 900px) {
  #config-accepted .modal {
    width: 80vw;
  }
}
@media screen and (max-width: 600px) {
  #config-accepted .modal {
    width: 90vw;
    padding: 35px 10px;
  }
}
#config-accepted .modal .heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
}
@media screen and (max-width: 600px) {
  #config-accepted .modal .heading {
    flex-flow: column nowrap;
  }
}
#config-accepted .modal .heading img {
  max-height: 60px;
  margin-right: 15px;
}
@media screen and (max-width: 600px) {
  #config-accepted .modal .heading img {
    max-height: 40px;
    margin-bottom: 15px;
  }
}
#config-accepted .modal .heading span {
  font-family: "PT Sans", sans-serif;
  font-size: 1.4rem;
}
@media screen and (max-width: 600px) {
  #config-accepted .modal .heading span {
    font-size: 1.3rem;
  }
}
#config-accepted .modal .buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
}
@media screen and (max-width: 900px) {
  #config-accepted .modal .buttons {
    flex-flow: column nowrap;
  }
}
@media screen and (max-width: 600px) {
  #config-accepted .modal .buttons {
    margin-top: 15px;
  }
}
#config-accepted .modal .buttons button {
  font-family: "kg_life_is_messy", sans-serif;
  font-size: 1.1rem;
  padding: 15px 10px;
  color: #542a01;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 13px;
  width: 400px;
  height: 100%;
  margin: 10px 0;
}
@media screen and (max-width: 1200px) {
  #config-accepted .modal .buttons button {
    font-size: 1.1rem;
    width: 400px;
  }
}
@media screen and (max-width: 900px) {
  #config-accepted .modal .buttons button {
    padding: 15px 5px;
  }
}
@media screen and (max-width: 600px) {
  #config-accepted .modal .buttons button {
    width: 100%;
    font-size: 0.9rem;
    height: 50px;
    padding: 0;
  }
}
#config-accepted .modal .buttons .next-config-btn {
  background-color: #fff;
  border: 3px solid #542a01;
  margin-right: 10px;
}
@media screen and (max-width: 900px) {
  #config-accepted .modal .buttons .next-config-btn {
    margin-right: 0;
  }
}
#config-accepted .modal .buttons .finish-btn {
  background-color: #ffc400;
  border: 3px solid #ffc400;
}
</style>
