var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"product-config"}},[_c('SelectModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSelectModal),expression:"showSelectModal"}],attrs:{"card-to-change":_vm.cardToChange,"side":_vm.side,"pexeso-mode":_vm.pexesoMode},on:{"toggleSelectModal":function($event){_vm.showSelectModal = !_vm.showSelectModal},"selectSecondPair":function($event){return _vm.togglePexesoSelect($event)}}}),(_vm.showCardBackModal)?_c('CardBackModal',{directives:[{name:"prevent-parent-scroll",rawName:"v-prevent-parent-scroll"}],on:{"toggleCardBackModal":function($event){_vm.showCardBackModal = !_vm.showCardBackModal}}}):_vm._e(),_c('InstructionModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInstModal),expression:"showInstModal"},{name:"prevent-parent-scroll",rawName:"v-prevent-parent-scroll"}],staticClass:"instruction-modal",on:{"closeModal":function($event){_vm.showInstModal = false}}}),_c('Navigator'),_c('div',{ref:"wrapperBox",staticClass:"wrapper"},[_c('div',{staticClass:"table"},[_c('div',{staticClass:"cube",class:_vm.getClassName()},[_c('div',{staticClass:"space-creator",style:({
            width: +_vm.tableWidth + 'px',
            height: +_vm.tableHeight + 'px'
          })}),_vm._l((_vm.tables[_vm.selectedTable].tables),function(i){return _c('div',{key:i,ref:"innerTable",refInFor:true,staticClass:"selected-table",class:_vm.getSideClass(i),style:({
              'grid-template-columns':
                'repeat(' + _vm.tables[_vm.selectedTable].columns + ', 1fr)',
              'grid-template-rows':
                'repeat(' + _vm.tables[_vm.selectedTable].rows + ', 1fr)'
            }),attrs:{"id":"target-table"}},_vm._l((_vm.tables[_vm.selectedTable]
                .selectedSigns[_vm.side]),function(item,index){return _c('div',{key:index,staticClass:"card"},[(index + 1 <= _vm.tables[_vm.selectedTable].columns)?_c('span',{staticClass:"col-num"},[_vm._v(_vm._s(index + 1))]):_vm._e(),_c('div',{staticClass:"relative-container"},[(item.name !== '')?_c('img',{staticClass:"replace-icon",attrs:{"src":!_vm.pexesoMode
                      ? '/conf/images/plus-math-brown-60.png'
                      : '/conf/images/delete.png',"alt":"replace-icon"},on:{"click":function($event){return _vm.cardAction(index)}}}):_vm._e(),_c('div',{staticClass:"card-content",class:{ flipped: index === _vm.flippedCard }},[_c('div',{staticClass:"front",class:[
                      {
                        'empty-sign-warn':
                          item.name === '' &&
                          _vm.tables[_vm.selectedTable].highlightEmpty ===
                            true
                      }
                    ],style:([
                      _vm.pexesoFrontCard &&
                      index !== _vm.pexesoFrontCard.cardToChange &&
                      _vm.pexesoSelectMode
                        ? { border: '2px dashed #777' }
                        : { border: '2px solid #777' }
                    ]),on:{"click":function($event){return _vm.cardHandler(index, item)}}},[_c('img',{attrs:{"src":_vm.getFrontImage(index, item),"alt":"item.name"}})]),_c('div',{staticClass:"back",on:{"click":function($event){return _vm.cardHandler(index, item)}}},[_c('img',{attrs:{"src":item.back_image,"alt":"item.name"}})])])]),_c('div',{staticClass:"card-description"},[_c('p',{staticClass:"absolute-txt"},[_vm._v(_vm._s(item.name))])])])}),0)})],2)]),_c('div',{staticClass:"left-leg"}),_c('div',{staticClass:"right-leg"}),_c('div',{staticClass:"sign-name"},[_c('h1',{staticClass:"table-heading"},[_vm._v(_vm._s(_vm.tables[_vm.selectedTable].name))])]),_c('div',{staticClass:"traverse"},[(_vm.tables[_vm.selectedTable].tables != 1)?_c('div',{staticClass:"table-switch"},[_c('img',{attrs:{"src":require("@/assets/icons/previous-arrow-100.png"),"alt":"previous-table-img"},on:{"click":function($event){return _vm.prevSide()}}}),_c('span',[_vm._v(_vm._s("TABULE " + (_vm.side + 1)))]),_c('img',{staticClass:"sideButton",attrs:{"src":require("@/assets/icons/next-arrow-100.png"),"alt":"previous-table-img"},on:{"click":function($event){return _vm.nextSide()}}})]):_vm._e()]),_c('div',{staticClass:"roof"},[_c('img',{attrs:{"src":require("@/assets/icons/trash_can.png"),"alt":"info-icon"},on:{"click":function($event){return _vm.$store.dispatch('api/purgeConfiguration')}}}),(_vm.pexesoMode)?_c('img',{attrs:{"src":require("@/assets/icons/card-back.png"),"alt":"info-icon"},on:{"click":function($event){!_vm.pexesoSelectMode ? (_vm.showCardBackModal = true) : ''}}}):_vm._e(),_c('img',{attrs:{"src":require("@/assets/icons/info-100.png"),"alt":"info-icon"},on:{"click":function($event){!_vm.pexesoSelectMode ? (_vm.showInstModal = true) : ''}}})]),_c('div',{staticClass:"bottom"})]),_c('NavigatorBtn',{staticClass:"navigator-btn"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }