export const sudo = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fillTable({ rootState }) {
      rootState.api.tables.forEach(table => {
        table.selectedSigns.forEach(side => {
          side.forEach(item => {
            let signCategory = Math.floor(
              Math.random() * Math.floor(rootState.api.signCategories.length)
            );
            let sign =
              rootState.api.signCategories[signCategory].signs[
                Math.floor(
                  Math.random() *
                    Math.floor(
                      rootState.api.signCategories[signCategory].signs.length
                    )
                )
              ];
            Object.assign(item, sign);
          });
        });
      });
    }
  }
};
